import React from "react"
import {PromotionalText} from '../components/PromotionalText'
//import {PromotionalBio} from '../components/PromotionalBio'
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby'

const About = ({data}) => {

  const items = data.allNodePromotional.nodes
  console.log(data)

  return (

    <Layout>
      <SEO title="About" />
      <div className="component-wrapper">
        <div className="row">
            <div className="col-12 col-md-8">
                {items.map(item => <div className="w-100 py-2" key={item.drupal_internal__nid}><PromotionalText promotional={item} /></div>)}
            </div>
            <div className="col-12 col-md-4">
                {/* <div className="w-100 py-2" ><PromotionalBio promotional={} /></div> */}
            </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    allNodePromotional {
      nodes {
        drupal_internal__nid
        title
        body {
          value
        }
      }
    }
  }
`

export default About;