import React from 'react'
import parse from 'html-react-parser';



const PromotionalText = ({promotional}) => {

    console.log(promotional)

    return (
        
            <div className="card about-card">
                <div className="row">
                    <div className="col-12 col-lg-5 image-col">
                        <div className="card-image">
                            <img className={promotional.drupal_internal__nid!==114 ? "border" : ""} src={promotional.image} alt="promotional display"/>
                        </div>
                    </div> 
                    <div className="col-12 col-lg-7 text-col">
                    <div className="card-body text-left">
                            <h5 className="card-title">{promotional.title}</h5>
                            <div className="card-text">{parse(promotional.body.value)}</div>
                        </div>
                    </div>
                </div>
            </div>
    )

}

export {PromotionalText}